var anchoventana=$(document).width();
var altoventana=$(document).height();
var md=768;

(function($) {
  var Sage = {
    'common': {
      init: function() {
        /**
         * declaracion variables globales
         */

         /**
          * init plugins y listeners comunes de proyectos
          */
         initPlugins();
         initListeners();

        /**
         * js del proyecto
         */
        $('#bt-conectarse').click(function(){
            document.location.href = '/identificacion';
          });

        $('#bt-desconectarse').click(function(){
            //$url_ajax = $(this).attr('data-href');
            $.ajax({
                    url: 'ajax/identificacion/desconectar',
                    type: 'GET',
                    dataType: 'json',
                    data:'&action=desconectar'
                })
                .done(function(result) {
                   document.location.href = '/';
                })
                .fail(function(result) {
                  document.location.href = '/';
            });
          });

        $('.js-ver-imagen').click(function() {
          //alert('img:: '+$(this).data('imagen'));
          _html = '<div class="col-12">';
          _html += '<img src="'+$(this).data('imagen')+'" class="img-fluid"></div><div class="col-2"><a class="btn btn-verde btn-descarga js-bt-descarga-imagen" ';
          _html += ' href="'+$(this).data('imagen')+'" download="'+$(this).data('imagen')+'"><i class="demo-icon icon-descargar"></i>DESCARGAR IMAGEN</a></div>';
          $('#modal-imagen .imagen').html(_html);
          $('#modal-imagen').modal('show');
        });

        $(".js-ver-imagen-carrusel").on("click touch",function(){
             // console.log('click thumbnail');
            cargarDatosImg($(this));
        });
        $('.prev-photo').on('click', function(){
              actual = $('.js-ver-imagen-carrusel.actual');
              siguiente = actual.prev();
              cargarDatosImg(siguiente);
        });
        $('.next-photo').on('click', function(){
              actual = $('.js-ver-imagen-carrusel.actual');
              siguiente = actual.next();
              cargarDatosImg(siguiente);
        });

        function cargarDatosImg(_obj){
            /*var img  = _obj.find(".img-book").attr("src");
            var tag  = _obj.find(".tags").html();
            var desc = _obj.find(".desc").html();
            var id =  _obj.attr("data-id");
            var fav = _obj.attr("data-fav");*/
            $('.js-ver-imagen-carrusel').removeClass('actual');
            _obj.addClass('actual');
//console.log(_obj.prev().html());
            //oculto next o prev
            if(!_obj.prev().length==0){
             $('.prev-photo').show();
            }else{
             $('.prev-photo').hide();
            }
            if(!_obj.next().length==0){
             $('.next-photo').show();
            }else{
             $('.next-photo').hide();
            }
            clase_icono = '';
            if (_obj.data('tipo') != 'imagen'){
              clase_icono = 'icono-documento-general';
            }
            _html = '<div class="col-12 text-center">';
            _html += '<img src="'+_obj.data('imagen')+'" class="img-fluid '+clase_icono+'"></div><div class="col-12 text-center mt-2"><a class="btn btn-verde-medio js-bt-descarga-imagen" ';
            _html += ' href="'+_obj.data('url-descarga')+'" download="'+_obj.data('url-descarga')+'"><i class="demo-icon icon-descargar"></i>DESCARGAR IMAGEN</a></div>';
            $('#modal-imagen-carrusel .imagen').html(_html);
            $('#modal-imagen-carrusel').modal('show');
            
        }


      },
      finalize: function() {
      }
    },
  };
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.