initListeners=function(){
  $(window).scroll(function(event) {
    valorScroll=$(window).scrollTop();

    // añade clase "enpantalla" cuando aparece en pantalla
    $(".js-aparece-scroll").each(function(index, el) {
      if ($(this).offset().top+$(this).height()<=valorScroll+$(window).height()){
        $(this).addClass("enpantalla");
      }else{
        $(this).removeClass("enpantalla");
      }
    });
  });

  /**
   * LISTENER validador formularios
   */
  $('.js-form-validator').validator(customValidations()).on('submit', function (e) {
    console.log('>>> ');
    if (e.isDefaultPrevented() ) {
      lista_errores = "";
      $(this).find('.has-error').each(function(index, element){
            if ($(this).attr('data-mensaje-error') !== 'undefined' && $(this).attr('data-mensaje-error') !== undefined && $(this).attr('data-mensaje-error') !== ''){
              lista_errores += '<li>'+$(this).attr('data-mensaje-error')+'</li>'; 
            }else{
              //lista_errores += '<li>'+$(this).find('label').html()+'</li>';            
            }
      });

      if (lista_errores !=''){
        lista_errores = '<ul>'+lista_errores+'</ul>';
      }

      alerta = $(this).data('alerta');

      if ($(this).attr('data-mensaje-error') !== 'undefined' && $(this).attr('data-mensaje-error') !== undefined && $(this).attr('data-mensaje-error') !== ''){
              //$('#myModal').mostrar_msg("¡Lo sentimos!", $(this).data('mensaje-error')+lista_errores);
              $('#'+alerta).find('span').html($(this).attr('data-mensaje-error')+lista_errores);
              $('#'+alerta).show();
              PLG_scrollTo(1,$('#'+alerta),0,-120);
      }else{
              //$('#myModal').mostrar_msg("¡Lo sentimos!", "Revisa todos los datos antes de continuar"+lista_errores);              
              $('#'+alerta).find('span').html("Revise todos los datos antes de continuar"+lista_errores);
              $('#'+alerta).show();
      }
    }
  });

  /**
   * LISTENER para mostrar/ocultar objetos
   */
  $(".js-toggle-div").click(function(){
    var obj=$(this).data("toggle-div");
    $(obj).toggleClass("desactivo");
  })

  /**
   * LISTENER menus con ancla
   */
  $(".js-menu-ancla").click(function(e){
    e.preventDefault();
    var ancla=$(this).attr("href");
    var offset=$(this).data("menuancla-offset");
    if(offset=="" || offset==undefined){
      offset=0;
    }
    PLG_scrollTo(1,ancla,0,offset,0);
  })

  /**
   * LISTENER para despliegue menú hamburguesa
   */
  $('#menu_desplegable').on('show.bs.collapse', function () {
   // PLG_showMenu();
    //PLG_closeModalLogin();
  });
  $("#menu_desplegable .close").click(function(){
    //PLG_closeMenu();
  });

  /**
   * LISTENER para mostrar modal login
   */
  $(".js-btn-header-login").click(function(){
    if($("#menu_desplegable").hasClass("show")){
      PLG_closeMenu(function(){
         PLG_toggleModalLogin();
      });
    }else{
      PLG_toggleModalLogin();
    }
  });

  /**
   * LISTENER para mostrar modal alerta
   */
  $(".js-modal-alerta").click(function(){
    PLG_showModalAlerta($(this).data("alerta-titulo"),$(this).data("alerta-texto"));
  });

  /**
   * LISTENER cierre cookies
   */
  $("#modal-cookies .close").click(function(){
    $("#modal-cookies").fadeOut();
    setCookie("cookie_ceim_web", "true", 365);
  });
  checkCookie();

  /**
   * LISTENER copiar de un input
   */
  $(".js-btn-copy").click(function() {
      var target=$(this).data("target-copy");
      copyToClipboard(document.getElementById(target));
  });

  /**
  * LISTENER compartires
  */
  $(".js-compartirFB").click(function(event) {
    var myWindow = window.open("https://www.facebook.com/sharer/sharer.php?u="+$(this).attr("data-url"), "sharer", "width=600,height=400");
  });

  $(".js-compartirLN").click(function(event) {
    var myWindow = window.open("https://www.linkedin.com/shareArticle?mini=true&url="+$(this).attr("data-url")+"&title="+$(this).attr("data-titulo")+"&summary=e"+$(this).attr("data-descripcion"), "sharer", "width=600,height=400");
  });

  $(".js-compartirTW").click(function(event) {
    var myWindow = window.open("https://twitter.com/home?status="+$(this).attr("data-descripcion")+" "+$(this).attr("data-url"), "sharer", "width=600,height=400");
  });
};