/**
 * init plugins
 */
initPlugins=function(){
  //algunos plugins interesa solo inicializarlos en mobile
  if (anchoventana>=md){
      /*animaciones WOW*/
        new WOW().init();

      /* scroll parallax */
        var s = skrollr.init();          
  }

  /* personaliza el check con un ON-OFF */
  var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
  elems.forEach(function(html) {
    var switchery = new Switchery(html,{
      color : '#f1426e'
    });
  });

  /* personaliza un slider */
  $("#slide").ionRangeSlider({
      type: "single",
      min: 0,
      max: 50,
      from: 1,
      step: 5,
      grid: true,
      grid_snap: true,
      values:["1","10","20","30","40","50"],
      grid_num: 5,
      postfix: "km"
  });

  /* el combo a un formato con más opciones */
  $(".chosen-select").chosen({disable_search_threshold: 10});

  /* personaliza el check */
  $('.i-checks').iCheck({
    checkboxClass: 'icheckbox_square-green',
    radioClass: 'iradio_square-green',
  });

  /* personaliza un campo numerico con + y - */
  $(".touchspin").TouchSpin({
      buttondown_class: 'btn btn-white',
      buttonup_class: 'btn btn-white',
      min:1,
      max:100
  });

  /* carrusel swiper */
  var swiper = new Swiper('.container-noticias', {
      pagination: '.swiper-pagination',
      slidesPerView: 1,
      paginationClickable: true, 
      nextButton:".swiper-button-next",
      prevButton:".swiper-button-prev",
      loop: true,
      autoplay: 3000,
      speed: 1500,
  });

  var swiper = new Swiper('.container-logos', {
    slidesPerView: 4,
    slidesPerColumn: 2,
    spaceBetween: 5,
    pagination: {
      el: '.swiper-pagination-logos',
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      }
    },
    nextButton:".siguiente",
    prevButton:".anterior",
    loop: false,
  });


  var swiper = new Swiper('.container-descarga-img', {
      pagination: '.swiper-pagination-img',
      slidesPerView: 3,
      spaceBetween: 15,
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        }
      },
      paginationClickable: true, 
      nextButton:".button-next",
      prevButton:".button-prev",
      loop: false,
  });

  var swiper = new Swiper('.container-images-noticia', {
      pagination: '.swiper-pagination-img-noticia',
      slidesPerView: 3,
      spaceBetween: 20,
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        }
      },
      paginationClickable: true, 
      nextButton:".btn-next",
      prevButton:".btn-prev",
      loop: false,
  });

  var swiper = new Swiper('.container-descarga-documentos', {
      pagination: '.swiper-pagination-documentos',
      slidesPerView: 4,
      spaceBetween: 5,
      breakpoints: {
        1200: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 2,
        },
        420: {
          slidesPerView: 1,
        }
      },
      paginationClickable: true, 
      nextButton:".next",
      prevButton:".prev",
      loop: false,
  });

  var swiper_eventos = new Swiper('.container-documentos-eventos', {
      //pagination: '.swiper-pagination-img-noticia',
      slidesPerView: 1,
      spaceBetween: 20,
      paginationClickable: true, 
      nextButton:".swiper-button-next",
      prevButton:".swiper-button-prev",
      loop: false,
  });
  var swiper_meetings = new Swiper('.container-documentos-reuniones', {
      //pagination: '.swiper-pagination-img-noticia',
      slidesPerView: 1,
      spaceBetween: 20,
      paginationClickable: true, 
      nextButton:".swiper-button-next",
      prevButton:".swiper-button-prev",
      loop: false,
  });
 
 /* moment.locale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')}
  );*/
  moment.lang('en', {
          week: { dow: 1 },
        });
        moment.updateLocale('en', {
          weekdaysMin : ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
          monthsShort:["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
          months:["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        });
  
  $(".js-date").datetimepicker({
    format: 'DD/MM/YYYY',
    //locale: 'es'
  });
  
  //$(".js-date").find(".icon-calendario").trigger("click");
  //console.log($('th.next').html()+' '+$('th.prev').html());
 // $('th.next').html('>');
 // $('th.prev').html('<');
};


